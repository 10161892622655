import React from 'react'

import Layout from '../components/layout'
import ToolboxOne from '../components/toolbox/ToolboxOne'
import ToolboxTwo from '../components/toolbox/ToolboxTwo'

const Services = props => (
  <Layout lang="pt" title="As Nossas Ferramentas" meta={{description: 'A nossa caixa de ferramentas evolui com o tempo. Conheça as ferramentas principais com que trabalhamos.'}} rootProps={{ className: 'page toolbox' }}>
    <ToolboxOne lang='pt' />
    <ToolboxTwo lang='pt' />
  </Layout>
)

export default Services
